import './App.css';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import LinksList from './LinkList.js';

function CommonLayout({
  ScientificName,
  CommonName,
  VarietyName,
  PurchasedFrom,
  PlantedOn,
  CareFacts,
  linkData,
  images,
}) {
  return (
    <div className="App">
      <div className="App-header">
        <h1 className="tap-root">
          Quackenbush
          <br />.garden
        </h1>
      </div>

      <br />

      <div className="namebox">
        <span className="name_header">Scientific Name:</span>
        <span className="name">
          <em>{ScientificName}</em>
        </span>
      </div>

      <br />
      <br />

      <div className="namebox">
        <span className="name_header">Common Name:</span>
        <span className="name">
          <em>{CommonName}</em>
        </span>
      </div>

      <br />
      <br />

      <div className="namebox">
        <span className="name_header">Variety Name:</span>
        <span className="name">
          <em>{VarietyName}</em>
        </span>
      </div>

      <br />
      <br />

      <ImageGallery items={images} showThumbnails={false} />

      <br />

      <div className="namebox">
        <div className="name_header">Details:</div>
        <div>Purchased from: {PurchasedFrom}</div>
        <div>Planted on: {PlantedOn}</div>
      </div>

      <br />
      <br />

      <div className="namebox">
        <div className="name_header">Basic Care and/or Facts:</div>
        <p>{CareFacts}</p>
      </div>

      <br />
      <br />

      <div className="namebox">
        <LinksList linkData={linkData} />
      </div>

      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default CommonLayout;
