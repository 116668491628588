import React from "react";

export function LinksList({ linkData }) {
  return (
    <div>
      <h2>List of Links</h2>
      <ul>
        {linkData.map((link, index) => (
          <li key={index}>
            <a href={link.href}>{link.text}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}
export default LinksList;
