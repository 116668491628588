import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Page from './Page';
import PageData from './pageData.json';

function App() {
  return (
    <Router>
      <Routes>
        {PageData.map((page) => (
          <Route
            key={page.PageID}
            path={`/pages/${page.PageID}`}
            element={<Page content={page} />}
          />
        ))}
      </Routes>
    </Router>
  );
}

export default App;
