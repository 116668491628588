import React from 'react';
import CommonLayout from './CommonLayout';

function Page({ content }) {
  // Extract data from content object
  const {
    ScientificName,
    CommonName,
    VarietyName,
    PurchasedFrom,
    PlantedOn,
    CareFacts,
    links,
    images,
  } = content;

  return (
    <CommonLayout
      ScientificName={ScientificName}
      CommonName={CommonName}
      VarietyName={VarietyName}
      PurchasedFrom={PurchasedFrom}
      PlantedOn={PlantedOn}
      CareFacts={CareFacts}
      linkData={links}
      images={images}
    />
  );
}

export default Page;
